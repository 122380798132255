.auction {
    padding: 50px 0;
    height: 100%;
}

.auctions__container {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.auctions__title {
    font-size: 50px;
    font-weight: 500;
}

.auctions__row {
    display: flex;
    column-gap: 30px;
    margin-top: 30px;
}

.auction__item {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    text-align: center;
    width: 400px;
    align-items: center;
    padding: 20px;

}

@media screen and (max-width: 450px) {
    .auction__item {
        width: 300px;
    }
}

.auction__info {
    max-width: 1139px;
    width: 100%;
    font-size: 20px;
    padding-top: 20px;
    padding-left: 17px;

}

.auction__info span {
    font-weight: 700;
}

.auction__item-link {
    color: black;
}

.auction__info {
    font-size: 18px;
}

.auction__item-title {
    font-size: 30px;
}

.auction-img {
    width: auto;
    max-width: 380px;
    height: 120px;
}

.auction__our__benefits {
    display: flex;
    column-gap: 90px;
    max-width: 80%;
    margin-top: 40px;
    align-items: center;
}

.auction__our__benefits_car {
    display: flex;
    column-gap: 90px;
    max-width: 80%;
    align-items: center;
    width: auto;
    height: 300px;
    border-radius: 10px;
}

.auction__our__benefits-box {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

}

.auction__our__benefits__title {
    font-size: 23px;
}

.auction__our__benefits__sub-title {
    font-size: 18px;
}

.auction__our__benefits {
    width: auto;
    height: 300px;
}

@media screen and (max-width: 1305px) {
    .auctions__row {
        flex-wrap: wrap;
        gap: 30px;
        justify-content: center;
    }

    .auction__our__benefits-box {
        order: 2;
    }

    .auction__our__benefits {
        order: 1;
    }

    .auction__our__benefits {
        height: 100%;
        flex-wrap: wrap;
        row-gap: 40px;
        justify-content: center;
        text-align: center;
        border-radius: 10px;
    }

    .auction__our__benefits_car {
        order: 1;
    }

    .auction__our__benefits_car {
        height: 100%;
        flex-wrap: wrap;
        row-gap: 40px;
        justify-content: center;
        text-align: center;
    }

}