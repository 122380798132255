.all__car__container {
    padding-top: 200px;
    padding-bottom: 100px;

}

.all__car__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding-top: 50px;
}

.all__car__controller {
    display: flex;
    justify-content: space-around;
    align-items: center;

}


.all__car__filter {

}

.all__car__filter-option {
    font-size: 20px;
}

.all__car__filter-select {
    font-size: 20px;
}
.all__car__filter {
    width: 100%;

}
@media screen and (max-width: 740px) {
    .all__car__controller {
        flex-direction: column;
        justify-content: center;
        row-gap: 30px;
    }



    .all__car__container {
        padding-top: 120px;
    }
}
