.car__price__calculator {
    background: linear-gradient(to right, rgba(30, 75, 115, 1), rgba(255, 255, 255, 0)), url('../assets/img/bg-calculator.jpg') no-repeat;
    background-size: cover;

}

.car__price__calculator__body {
    display: flex;
    column-gap: 80px;
    align-items: start;
    margin-top: 40px;

}

@media screen and (max-width: 950px) {
    .car__price__calculator__body {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.car__price__calculator {
    padding: 80px 0;
}

.car__price__calculator__form {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    min-width: 420px;
    width: 50%;
    padding: 20px;
}

.car__price__calculator__title {
    text-align: center;
    color: white;
    font-size: 40px;
}

.car__price__calculator__form-item-box {
    display: flex;
    column-gap: 30px;
    max-width: 700px;

}

.select-container-box {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    width: 100%;
}

.select-container-title {
    color: white;
}

.select-container {
    position: relative;
    width: 100%;

    height: 100%;
}

.select-container .select {
    position: relative;
    background: rgba(0, 0, 0, 0.5);
    height: 40px;
    width: 100%;
}

.select-insert {
    position: relative;

    height: 40px;
    width: 100%;
}

.select-container .select::after {
    position: absolute;
    content: "";
    width: 15px;
    height: 15px;
    top: 50%;
    right: 15px;
    transform: translateY(-50%) rotate(45deg);
    border-bottom: 2px solid white;
    border-right: 2px solid white;
    cursor: pointer;
    transition: border-color 0.4s;
}

.select-container.active .select::after {
    border: none;
    border-left: 2px solid white;
    border-top: 2px solid white;
}

.select__input {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0 15px;
    background: none;
    outline: none;
    border: none;
    font-size: 1.4rem;
    color: white;
    cursor: pointer;
}

.select__input-insert {
    border-radius: 10px;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0 15px;
    background: white;
    outline: none;
    border: none;
    font-size: 1.4rem;
    color: black;
}

.car__price__calculator-select {
    width: 90%;
    background: white;
    height: 40px;
    font-size: 18px;
    color: #000000;
}

.option-container {
    border-radius: 10px;
    width: 100%;
    position: relative;
    background: white;
    overflow-y: auto;
    z-index: 150;
}

.option {
    padding: 0 15px;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 20px;
    color: white;
    background: rgba(0, 0, 0, 0.2);
}

.option:hover {
    padding-left: 20px;
}

.option.selected {
    background: rgba(0, 0, 0, 0.2);
}

.car__price {
    max-width: 500px;
    min-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.car__price-callback {
    margin-top: 30px;
    width: 80%;
}

.car__price-title {
    text-align: center;
    font-size: 30px;
    color: #FFFFFF;
}

.car__price-select-title {
    font-size: 20px;
    text-align: center;
}

.car__price-select {
    margin-top: 10px;
}

.car__price-insert {
    height: 40px;
    width: 100%;
}

/*BTN*/

.car__price-btn {
    min-width: 200px;
    padding: 5px 30px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.15rem;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    font-size: 18px;
    text-decoration: none;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    height: 50px;
    cursor: pointer;
    align-items: center;
    margin-top: 30px;
}

.car__price-btn:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #29066B;
    border-radius: 5px;
    z-index: -2;
}

.car__price-btn:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #D4D342;
    transition: all ease 0.5s;
    border-radius: 5px;
    z-index: -1;
}

.car__price-btn:hover:before {
    width: 100%;

}

.car__price-btn:hover {
    color: #29066B;
}

.select-container-cancel {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 5px;
    top: 10px;
    cursor: pointer;
}

@media screen and (max-width: 950px) {

    .car__price__calculator__form {
        width: 100%;
    }

    .car__price__calculator__form-item-box {
        max-width: none;
    }
}

@media screen and (max-width: 700px) {
    .car__price__calculator__form-item-box {
        flex-direction: column;
        row-gap: 30px;
    }

    .car__price__calculator__form {
        min-width: 300px;
    }

    .car__price__calculator__form {
        width: 100%;
    }
}

@media screen and (max-width: 400px) {
    .car__price-title {
        font-size: 25px;
    }

    .car__price-btn {
        padding: 5px 15px
    }

    .car__price-callback {
        max-width: 266px;
    }

    .select__input-insert {
        padding: 0;
        width: 100%;
    }

}