.reviews {
    padding: 50px 0;
}

.reviews_name__title {
    display: flex;
    justify-content: flex-start;
    padding-bottom: 10px;
    align-items: center;
    width: 100%;
}

.reviews__row {
    display: flex;
    gap: 30px;
    margin-top: 30px;
    justify-content: center;
    flex-wrap: wrap;
}

.reviews__title {
    font-size: 50px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 30px;

}

.reviews__container {
    padding-top: 50px;
}

.reviews__item {
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F6F6F6;
    min-width: 300px;
    padding: 10px;
    border-radius: 20px;
    text-align: center;
    max-width: 400px;
    height: 100%;
    min-height: 200px;
    max-height: 200px;
}

.reviews__username {
    margin-top: 10px;
    font-size: 20px;
    max-width: 300px;
    word-wrap: break-word;
}

.reviews__text {
    text-align: center;
    max-width: 300px;
    word-wrap: break-word;
    overflow: auto;
}

.reviews__slide::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.42);
    z-index: 20;
}

.icon-arrow-long-left {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    padding: 10px;
    margin: auto;
}

.icon-arrow-long-right {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    padding: 10px;
    margin: auto;
}

.icon-arrow-long-left:hover {
    border: solid gray;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    padding: 10px;
    margin: auto;
}

.icon-arrow-long-right:hover {
    border: solid gray;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    padding: 10px;
    margin: auto;
}

.sliderContainer {
    display: flex;
}

.swiper{
    padding-bottom: 35px;
    width: 100%;
}

.swiper-pagination {
    bottom: 0px;
}

#style-3::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 1px;
	background-color: #F5F5F5;
    border-radius: 20px;
}

#style-3::-webkit-scrollbar {
	width: 6px;
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar-thumb {
	background-color: #29066B;
    border-radius: 10px;
}

@media screen and (max-width: 850px) {
    .icon-arrow-long-left {
        display: none;
    }
    
    .icon-arrow-long-right {
        display: none;
    }
}

@media screen and (max-width: 1305px) {
    .reviews {
        padding: 50px 0 50px 0;
    }

    .reviews__row {
        flex-wrap: wrap;

    }

    .reviews__text {
        text-align: center;
        max-width: 300px;
    }

}

@media screen and (max-width: 850px) {
    .reviews__item {
        max-width: none;
    }
}

.reviews__video iframe {
    max-width: 500px;
    width: 100%;
    height: 400px;
}

@media screen and (max-width: 500px) {
    .reviews__video {
        height: 400px;
    }

    .swiper{
        padding-bottom: 15vh;
    }
}