.header {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    position: fixed;
    height: 150px;
    width: 100vw;
    background: white;
    z-index: 200;
    background: linear-gradient(45deg, #D4D342 20%, #29066B 19%);
    color: white;
    background-size: cover;
    align-items: center;
    justify-items: center;
}

.header-btn-label {
    white-space: nowrap;
}

.header__container {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.header-logo {
    width: 170px;
    height: auto;
    margin-left: 30px;
    display: flex;
    justify-self: flex-start;
}

.menu {
    display: flex;
    column-gap: 30px;
}

.menu__link {
    text-decoration: none;
    font-size: 20px;
    cursor: pointer;
    color: white;
    white-space: nowrap;
}

.header-btn {
    color: white;
    font-size: 20px;
    width: fit-content;
    height: fit-content;
}

.hamburger-menu {
    display: none;
}

.menu__box {
    display: block;
    position: fixed;
    visibility: hidden;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 80px 0;
    list-style: none;
    text-align: center;
    background-color: #ECEFF1;
    box-shadow: 1px 0px 6px rgba(0, 0, 0, .2);
}

#menu__toggle:checked~.menu__btn>span {
    transform: rotate(45deg);
}

#menu__toggle:checked~.menu__btn>span::before {
    top: 0;
    transform: rotate(0);
}

#menu__toggle:checked~.menu__btn>span::after {
    top: 0;
    transform: rotate(-90deg);
}

#menu__toggle:checked~.menu__box {
    visibility: visible;
    right: 0;
}

#menu__toggle:checked~body {
    overflow: hidden;
}

.hamburger-menu {
    display: none;
}

#menu__toggle {
    opacity: 0;
}

.menu__btn {
    display: flex;
    align-items: center;
    position: absolute;
    top: 40px;
    right: 15px;
    width: 26px;
    height: 26px;
    cursor: pointer;
    z-index: 1;
}

.menu__btn>span,
.menu__btn>span::before,
.menu__btn>span::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #616161;
}

.menu__btn>span::before {
    content: '';
    top: -8px;
}

.menu__btn>span::after {
    content: '';
    top: 8px;
}

.menu__btn>span,
.menu__btn>span::before,
.menu__btn>span::after {
    transition-duration: .25s;
}

.menu__box {
    transition-duration: .25s;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 30px;
    height: 100vh;
}

@media (max-width: 1100px) {
    .header {
        background: #D4D342;
    }
}

@media (max-width: 1160px) {
    .header {
        height: 100px;
    }

    .header__container {
        display: flex;
        justify-content: start;
    }

    .header-logo {
        width: 100px;
        height: auto;
    }

    .hamburger-menu {
        display: block;
    }

    .btn-not-adaptive {
        display: none;
    }

    .btn-adaptive {
        max-width: 300px;
    }

    .nav {
        display: none;
    }

    .menu__link {
        font-size: 18px;
        margin-bottom: 10px;
        color: black;
    }
}