.backdrop {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 600;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-transition: opacity 500ms linear, visibility 500ms linear;
    transition: opacity 500ms linear, visibility 500ms linear;
    overflow: hidden;
}

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    -webkit-transition: -webkit-transform 500ms linear;
    transition: -webkit-transform 500ms linear;
    width: 100%;
    min-height: 100%;
    padding: 40px;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}
@media screen and (min-width: 480px) {
    .modal {
        width: 450px;
        min-height: 609px;
    }
}
@media screen and (min-width: 1200px) {
    .modal {
        width: 528px;
        min-height: 581px;
    }
}

.modal-close {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    top: 8px;
    right: 8px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.close-icon {
    width: 16px;
    height: 16px;
}


.modal-close:hover {
    background-color: rgba(226, 220, 220, 0.829);
}

.is-hidden {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
}

.backdrop.is-hidden .modal {
    -webkit-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0);
}

.modal-title {
    text-align: center;
    margin-bottom: 12px;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.15;
    text-align: center;
    letter-spacing: 0.03em;
    color: #212121;
}

.input-form {
    display: block;
    width: 100%;
    height: 40px;
    border: 1px solid rgba(33, 33, 33, 0.2);
    border-radius: 4px;
    text-indent: 10px;
    outline: none;
    cursor: pointer;
    -webkit-transition: border-color 250ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: border-color 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
.input-form:hover, .input-form:focus {
    border-color: #D4D342;
}

.input-title {
    display: block;
    margin-bottom: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.16;
    letter-spacing: 0.01em;
    color: #757575;
}

.form-field {
    margin-bottom: 10px;
}

.form-field-comment {
    margin-bottom: 20px;
}

.form-field-politic {
    margin-bottom: 30px;
}

.input-comment {
    resize: none;
    height: 120px;
    padding: 12px 16px;
}
.input-comment::-webkit-input-placeholder {
    font-weight: 400;
    font-size: 12px;
    line-height: 1.16;
    letter-spacing: 0.01em;
    color: rgba(117, 117, 117, 0.5);
}
.input-comment::-moz-placeholder {
    font-weight: 400;
    font-size: 12px;
    line-height: 1.16;
    letter-spacing: 0.01em;
    color: rgba(117, 117, 117, 0.5);
}
.input-comment:-ms-input-placeholder {
    font-weight: 400;
    font-size: 12px;
    line-height: 1.16;
    letter-spacing: 0.01em;
    color: rgba(117, 117, 117, 0.5);
}
.input-comment::-ms-input-placeholder {
    font-weight: 400;
    font-size: 12px;
    line-height: 1.16;
    letter-spacing: 0.01em;
    color: rgba(117, 117, 117, 0.5);
}
.input-comment::placeholder {
    font-weight: 400;
    font-size: 12px;
    line-height: 1.16;
    letter-spacing: 0.01em;
    color: rgba(117, 117, 117, 0.5);
}

.icon-container {
    position: relative;
}

.icon-input {
    position: absolute;
    top: 50%;
    left: 12px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: fill 250ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: fill 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.politic-title {
    font-weight: 400;
    font-size: 12px;
    line-height: 1.16;
    letter-spacing: 0.03em;
    color: #757575;
}
@media screen and (min-width: 1200px) {
    .politic-title {
        font-size: 14px;
        line-height: 1.71;
    }
}

.politic-link {
    color: #D4D342;
    margin-left: 10px;
}

.form-field-politic {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.icon-politic {
    border: 2px solid #212121;
    border-radius: 4px;
    margin-right: 7px;
    cursor: pointer;
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), border-color 250ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), border-color 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.politic-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.checkbox-politic{
    margin-right: 5px;
    accent-color: #D4D342;
}
.checkbox-politic:checked + .politic-title > .icon-politic {
    background-color: #2196f3;
    border-color: #2196f3;
}

.input-form:hover + .icon-input,
.input-form:focus + .icon-input {
    fill: #D4D342;
}


/*BTN*/
.form-button-box{
    display: flex;
    column-gap: 30px;

}

.form-button {
    display: flex;
    align-items: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    min-width: 200px;
    padding: 5px 30px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.15rem;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    font-size: 18px;
    text-decoration: none;
    border-radius: 5px;
}
.form-button span{
    color: #fff;
    display: block;
}
.form-button span:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 55%;
    width: 0%;
    height: 100%;
    background-color: #000;
    transition: all ease 0.4s;

    z-index: -1;
}
.form-button span:hover:before {
    width: 45%;
}
.form-button span:hover {
    color: #ffffff;
}
.form-button:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background:  #000 ;
    border-radius: 5px;
    z-index: -2;
}
.form-button:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #D4D342;
    transition: all ease 0.5s;
    border-radius: 5px;
    z-index: -1;
}
.form-button:hover {
    color: #000;
}
.form-button:hover:before {
    width: 100%;
}
.form-button:hover span:before  {
    width: 100%;

}
.form-button:hover span  {
    color: #FFFFFF;
}
/*BTN*/
