.footer {
    background: linear-gradient(45deg, #29066B 50%, #D4D342 50%);
    color: white;
    padding: 30px 0 0 0;

}

.footer__contact-row {}

.footer__contact-row {
    display: flex;
    justify-content: center;
    column-gap: 90px;
}

.footer__contact__item {
    max-width: 500px;
    display: flex;
    column-gap: 30px;
    align-items: center;
    color: #D4D342;
    padding: 5px 20px;
    width: 100%;
}

.phone__number__color {
    color: #29066B;
}

.footer__contact__icon__box {
    width: 60px;
    height: 60px;
}

.footer__contact__icon {
    width: 60px;
    height: 60px;
}

.footer__contact__icon path {}

.footer__contact__icon-custom {
    fill: #D4D342;
}

.footer__contact-title {
    font-size: 30px;
}

.footer__adress_style {
    color: #D4D342;
}

.footer__contact-info {
    display: flex;
    flex-direction: column;
    font-size: 20px;
}
.footer__address-info{
    font-size: 16px;
}
.footer__map-box {
    margin-top: 30px;

}

.footer__map {
    width: 100%;
    height: 400px;
}

.footer__social-link-row {
    display: flex;
    justify-content: space-around;
    padding: 20px 0;
}

.footer__social-link__item {
    display: flex;
    max-width: 400px;
    column-gap: 15px;
}

.footer__social-link-icon {
    width: 30px;
    height: auto;
}

.footer__social-link__name {
    text-decoration: none;
    color: #D4D342;
    font-size: 20px;
}

.black {
    color: #29066B;
}

@media screen and (max-width: 1100px) {
    .footer {
        background: linear-gradient(180deg, #29066B 50%, #D4D342 50%);
    }

    .footer__contact__icon {
        fill: #D4D342;
    }

    .footer__contact-info {
        color: #D4D342;
    }

    .phone__number__color {
        color: #D4D342;
    }

    .footer__contact-row {
        gap: 20px;
        flex-wrap: wrap;
        justify-content: center;
    }

    .footer__social-link__name {
        color: black;
    }

    .footer__social-link__item {
        width: 130px;

    }

    .footer__social-link-row {
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 680px) {
    .footer__contact__item {
        max-width: 320px;
    }

    .footer__contact__icon__box {
        width: 40px;
        height: 40px;
    }

    .footer__contact__icon {
        width: 40px;
        height: 40px;
    }

    .phone__number__color {
        color: #D4D342;
    }

    .footer__contact-title {
        font-size: 20px;

    }

    .footer__contact-info {
        font-size: 17px;
    }
}