.benefits {
    padding: 80px 0;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(255, 255, 255, 0)), 35% url("../assets/img/benefits-bg.jpg") no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-size: cover;
    background-position: center;
}

.benefits-background {

    height: 100%;
}

.benefits__container {
    position: relative;
    z-index: 100;
}

.benefits__title {
    text-align: center;
    font-size: 40px;
    color: white;
}

.benefits__row {
    display: flex;
    justify-content: center;
    column-gap: 60px;
    margin-top: 40px;
}

.benefits__item {
    max-width: 300px;
    display: flex;
    justify-content: start;
    flex-direction: column;
    padding: 10px;
    text-align: center;
    background-color: #F6F6F6;
    border-radius: 10px;
    background: rgba(0,0,0,.4);
}

.benefits__item__title {
    font-size: 28px;
    font-weight: 600;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.benefits__item_info {
    font-size: 20px;
    margin-top: 14px;
    color: white;
}

@media (max-width: 1412px) {

    .benefits__row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
    }

    .benefits-background {
        height: 85vh;
    }
}

@media (max-width: 702px) {

    .benefits__row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
    }

}

@media (max-width: 649px) {

    .benefits__row {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        margin-top: 20px;
    }
    
}