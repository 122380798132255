.hero {
    background: linear-gradient(to right, rgba(30, 75, 115, 1), rgba(255, 255, 255, 0)), url("../assets/img/istockphoto-843079144-2048x2048-transformed.jpeg") no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-size: cover;
    height: 100vh;
    color: white;
}

@media screen and (max-width: 1100px) {
    .hero {
        background: linear-gradient(to right, rgba(30, 75, 115, 1), rgba(255, 255, 255, 0)), url("../assets/img/hero_mobile.jpeg") no-repeat;
        height: 100%;
        row-gap: 40px;
    }
}

.hero__container {
    padding-top: 200px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.hero__content {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}

.hero__title {
    font-size: 60px;
    font-weight: 500;
}

.hero__sub-title {
    font-size: 30px;
    font-weight: 500;
}

.hero__benefits {
    font-size: 40px;
    display: flex;
    flex-direction: column;
    color: #D4D342;
    letter-spacing: 1px;
    font-weight: 600;

}

.hero__benefits-custom {
    font-size: 20px;
    font-weight: 600;
}

.hero__scroll-down {
    width: 50px;
    height: auto;
    padding-bottom: 30px;
}

.hero-btn-box {
    max-width: 300px;
    text-align: center;
}

.hero-btn {
    width: 100%;
}

.hero-card__row {
    display: flex;
    justify-content: center;
}

.hero-card__benefits {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.hero-card__benefits-text {
    font-size: 25px;
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.hero-card__benefits-svg {
    width: 20px;
    height: auto;
    fill: #000;
}

.hero__img-box {
    max-width: 800px;
}

.hero__img {
    height: auto;
    max-width: 100%;
}


@media (max-width: 1100px) {
    .hero__container {
        display: flex;
        flex-wrap: wrap;
        padding: 100px 0 50px 0;
    }

    .hero__content {
        order: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
    }

    .hero__sub-title {
        text-align: center;
        display: flex;
        justify-content: center;
    }

    .hero-btn-box {
        display: flex;
        text-align: center;
    }

    .hero__img-box {
        order: 1;
    }
}

@media (max-width: 1100px) {
    .hero__title {
        font-size: 40px;
    }

    .hero-card__benefits-text {
        font-size: 20px;
    }

    .hero__benefits {
        font-size: 25px;
    }
}