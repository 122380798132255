*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

ul,
ol,
li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
}

html,


body {
  font-family: Dosis;
  letter-spacing: 1px;
}

#menu__toggle:checked~body {
  overflow: hidden;
}

.App {
  overflow-x: hidden;

}

body:has(.backdrop) {
  overflow: hidden;

}

.container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 10px;
}

/*BTN*/
.btn-box {
  display: flex;
  column-gap: 30px;

}

@media screen and (max-width: 550px) {
  .btn-box {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }
}

.btn {
  min-width: 200px;
  padding: 5px 30px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  font-size: 18px;
  text-decoration: none;
  border-radius: 5px;
  display: flex;
  cursor: pointer;
  justify-content: center;
}

.btn span {
  color: #29066B;
  display: block;
}

.btn span:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 55%;
  width: 0%;
  height: 100%;
  background-color: #29066B;
  transition: all ease 0.4s;

  z-index: -1;
}

.btn span:hover:before {
  width: 45%;
}

.btn span:hover {
  color: #ffffff;
}

.btn:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #29066B;
  border-radius: 5px;
  z-index: -2;
}

.btn:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #D4D342;
  transition: all ease 0.5s;
  border-radius: 5px;
  z-index: -1;
}

.btn:hover {
  color: #29066B;
}

.btn:hover:before {
  width: 100%;
}

.btn:hover span:before {
  width: 100%;

}

.btn:hover span {
  color: #FFFFFF;
}