.callback{
    background:  linear-gradient(to left, rgba(30, 75, 115, 1), rgba(255, 255, 255, 0)) , url("../assets/img/coupe-1374450_1920.jpg") center no-repeat ;
    overflow: hidden;
    color: white;
    backdrop-filter: blur(10px);
    background-position: center;
    background-size: cover;
}

.callback__container{
    padding: 120px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 100px;
    text-align: center;
}
.callback__img{
    width: 100px;
    height: auto;
}
.callback__title{
    font-size: 50px;
}
.callback__text{
    font-size: 30px;
}
.callback__text-custom{
    color: #D4D342;
}

