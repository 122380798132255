.actual-car {
    padding: 50px 0;
}

.actual-car__row {
    display: flex;
    align-items: center;
}

.actual-car__title {
    text-align: center;
    font-size: 50px;
    font-weight: 500;
    margin-bottom: 30px;
}

.actual-car__name {
    font-size: 20px;
    font-weight: bold;
}

.actual-car__item {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    row-gap: 15px;
    padding: 0 0 15px 0;
    min-width: 250px;
}
.actual-car__item__img__box{
    align-items: center;
    background: #fff;
    display: flex;
    height: 300px;
    justify-content: center;
    overflow: hidden;
    width: 100%;
}
.actual-car__img {
    height: 100%;
    object-fit: cover;
    width: 100%;
    border-radius: 20px;
}

.actual-car__info {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    row-gap: 15px;
    position: relative;
    padding: 5px 10px;
    font-size: 18px;
    font-weight: 500;
}

.acrtual-car__details_info {
    color: darkgray;
}

.actual-car__details {
    display: grid;
}

.actual-car__details__item {
    display: grid;
    gap: 15px;
}

.actual-car__details__info {
    display: flex;
    justify-content: space-between;
}

.slick-slide {
    display: flex !important;
    flex-direction: column;
    row-gap: 50px;
}

.slick-list {
    overflow: visible !important;
}

.actual-car__price {
    color: red;
    font-size: 24px;
    font-weight: 500;
}

.actual-car__link-box {
    display: none;
    width: 100%;
    position: absolute;
    top: -45px;
    left: 0;
}

.actual-car__link {
    height: 30px;
    text-decoration: none;
    color: #ffff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D4D342;
}

.actual-car__item:hover .actual-car__link-box {
    display: block;
}

.actual-car__btn-box {
    display: flex;
    justify-content: center;
    column-gap: 20px;
    padding-bottom: 20px;
}

.actual-car__btn {
    border: 1px solid #D4D342;
    width: 108px;
    height: 36px;
    background-color: #ffff;
    font-size: 18px;
}

.actual-car__btn:hover {
    background-color: #D4D342;
    color: #ffff;
}

@media screen and (max-width: 500px) {
    .actual_car_swpier .swiper-slide {
        display: flex;
        justify-content: center;
    }
}

.car__price-button {
    padding: 10px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.15rem;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    font-size: 16px;
    text-decoration: none;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
}

.car__price-button:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #29066B;
    border-radius: 5px;
    z-index: -2;
}

.car__price-button:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #D4D342;
    transition: all ease 0.5s;
    border-radius: 5px;
    z-index: -1;
}

.car__price-button:hover:before {
    width: 100%;
}

.car__price-button:hover {
    color: #29066B;
}

.icon-arrow-long-left {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    padding: 10px;
}

.icon-arrow-long-right {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    padding: 10px;
}

.icon-arrow-long-left:hover {
    border: solid gray;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    padding: 10px;
}

.icon-arrow-long-right:hover {
    border: solid gray;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    padding: 10px;
}

.swiper-pagination {
    bottom: 0px;
}

@media screen and (max-width: 850px) {
    .icon-arrow-long-left {
        display: none;
    }
    
    .icon-arrow-long-right {
        display: none;
    }
}

@media screen and (max-width: 400px) {
    .car__price-button {
        padding: 5px 15px
    }
}