.car-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.car-details__container {
    padding: 180px 0 120px 0;

}

.car-details__row {
    display: flex;
    column-gap: 50px;
    align-items: center;
}

.car-details__slider-box {
    max-width: 600px;
    width: 100%;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}

.car-details__slider-box .swiper {
    max-width: 600px;
    width: 100%;
}

.car-details__slider-box .swiper-wrapper {
    max-width: 600px;
    width: 100%;
}

.car-details__img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    object-position: center;
}

@media screen and (max-width: 600px) {
    .car-details__img {
        width: 100%;
        object-fit: contain;
        height: 100%;
    }
}

.car-details__img-mini {
    height: 80px !important;
    object-fit: cover;
    width: 140px;
}

.car-details__info {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.car-details__title {
    font-size: 40px;
}

.car-details__price {
    font-size: 30px;
    color: #D4D342;
}

.car-details__characteristics-box {
    display: flex;
    column-gap: 30px;
}

.car-details__characteristics__item {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

}

.car-details__characteristics {
    font-size: 18px;
}

@media screen and (max-width: 1270px) {
    .car-details__row {
        flex-direction: column;

    }

    .car-details__info {
        margin-top: 30px;
    }

    .car-details__slider-box {
        max-width: 600px;
    }
}

@media screen and (max-width: 640px) {
    .car-details__slider-box {
        max-width: 500px;
    }
}

@media screen and (max-width: 550px) {
    .car-details__slider-box {
        width: 400px;
    }
}

@media screen and (max-width: 460px) {
    .car-details__slider-box {
        width: 300px;
    }
}

/*BTN*/
.form-button-box {
    display: flex;
    column-gap: 30px;

}

@media screen and (max-width: 550px) {
    .form-button-box {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px;
    }
}

.form-button {
    min-width: 200px;
    padding: 5px 30px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.15rem;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    font-size: 18px;
    text-decoration: none;
    border-radius: 5px;
    display: flex;
    justify-content: center;
}

.form-button span {
    color: #000;
    display: block;
}

.form-button span:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 55%;
    width: 0%;
    height: 100%;
    background-color: #000;
    transition: all ease 0.4s;

    z-index: -1;
}

.form-button span:hover:before {
    width: 45%;
}

.form-button span:hover {
    color: #ffffff;
}

.form-button:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    border-radius: 5px;
    z-index: -2;
}

.form-button:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #D4D342;
    transition: all ease 0.5s;
    border-radius: 5px;
    z-index: -1;
}

.form-button:hover {
    color: #000;
}

.form-button:hover:before {
    width: 100%;
}

.form-button:hover span:before {
    width: 100%;

}

.form-button:hover span {
    color: #FFFFFF;
}

/*BTN*/
.car-details__description__title {
    font-size: 30px;

}